// eslint-disable-next-line import/no-cycle
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  adminRoot,
  goFiberUrl,
} from 'constants/defaultValues';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { faEnvelope, faExclamation, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IntlMessages from './IntlMessages';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, "dark.blueyale");
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, "rounded");
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem('currentLanguage')
        ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user;
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};
export const createNotification = (type, title, messages, className) => {
  const cName = className || '';
  switch (type) {
    case 'primary':
      NotificationManager.primary(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'secondary':
      NotificationManager.secondary(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'info':
      NotificationManager.info(messages, title, 3000, null, null, cName);
      break;
    case 'success':
      NotificationManager.success(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'warning':
      NotificationManager.warning(
        messages,
        title,
        3000,
        null,
        null,
        cName
      );
      break;
    case 'error':
      NotificationManager.error(
        messages,
        title,
        5000,
        null,
        null,
        cName
      );
      break;
    default:
      NotificationManager.info(messages);
      break;
  }
};

export const getToken = () => {
  return Cookies.get("Token")
}

export const getApiKey = () => {
  return Cookies.get("API_Key")
}

export const isLoggedIn = () => {
  const token = Cookies.get('Token'); // Token'ı buraya yerleştirin
  const decodedToken = jwt_decode(token);

  const expirationTime = decodedToken.exp * 1000; // Saniye cinsinden son kullanma tarihini milisaniyeye çeviriyoruz
  const currentTime = Date.now(); // Şu anki zamanı alıyoruz

  if (currentTime > expirationTime) {
    createNotification("error", <IntlMessages id={"config.alert-logOut-title"} />, <IntlMessages id={"config.alert-logOut-message"} />, "filled")
    Cookies.set("Token", "")
    setTimeout(() => {
      window.location.replace(`${adminRootLogin}`)
    }, 500);
  }
}

export const isExpired = (expire_date) => {
  let date = moment(expire_date, "DD/MM/YYYY")
  const now = moment();
  const expire = moment(date);
  const diff = expire.diff(now, 'seconds');
  const duration = moment.duration(diff, 'seconds');
  const days = duration.days();
  const months = duration.months();
  const years = duration.years();

  if (years < 0 || months < 0 || days < 0) {
    return true
  } else
    return false
}

export const CheckUserConnection = async () => {
  return await axios.post(goFiberUrl + "/api/m365/check_user_connection", { ApiKey: Cookies.get('API_Key') }).then((resp) => {

    Cookies.set("m365Status", JSON.stringify(resp.data))
    return resp.data

  }).catch((err) => {
    console.error(err)
    createNotification("error", "Opps!", "Something went wrong about connection status!", "filled")
  })
}

export const ButtonOnExporting = (e, ref, fileName = "DataGrid", workSheet = "Main sheet") => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(workSheet);

  const dataGrid = ref.current.instance;

  exportDataGrid({
    component: dataGrid,
    worksheet: worksheet,
    keepColumnWidths: true,
    customizeCell: function (options) {
      const excelCell = options;
      excelCell.font = { name: 'Arial', size: 12 };
      excelCell.alignment = { horizontal: 'left' };
      excelCell.wrapText = true;
    }
  }).then(function () {
    workbook.xlsx.writeBuffer()
      .then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
      });
  });
  e.cancel = true;
}


// That's not completed yet
export const masterDetailExport = (e, ref, fileName = "DataGrid", workSheet = "Main sheet") => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  let masterRows = []
  const dataGrid = ref.current.instance;

  exportDataGrid({
    component: dataGrid,
    worksheet: worksheet,
    autoFilterEnabled: true,

    customizeCell: function (options) {
      const excelCell = options.excelCell;
      const gridCell = options.gridCell;
      options.font = { name: 'Arial', size: 12, color: "red" };
      options.alignment = { horizontal: 'left' };
      if (
        gridCell.column.dataField === "TarihSaat" &&
        gridCell.rowType === "data"
      ) {
        masterRows.push({
          rowIndex: excelCell.fullAddress.row + 1,
          data: gridCell.data
        });
      }
    }
  }).then((cellRange) => {
    const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
    let offset = 0;

    const insertRow = (index, offset, outlineLevel) => {
      const currentIndex = index + offset;
      debugger;
      const row = worksheet.insertRow(currentIndex, [], "n");

      for (var j = worksheet.rowCount + 1; j > currentIndex; j--) {
        worksheet.getRow(j).outlineLevel = worksheet.getRow(
          j - 1
        ).outlineLevel;
      }

      row.outlineLevel = outlineLevel;

      return row;
    };

    for (var i = 0; i < masterRows.length; i++) {
      let row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
      let columnIndex = cellRange.from.column + 1;
      row.height = 20;

      let employeeData = bulkData.__rawData.find(
        (item) => item.ID === masterRows[i].data.ID
      );
      Object.assign(row.getCell(columnIndex), {
        value: "Detail",
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BEDFE6" }
        }
      });
      worksheet.mergeCells(row.number, columnIndex, row.number, columns.length + 1);

      row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
      columns.forEach((columnName, currentColumnIndex) => {
        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
          value: columnName,
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "BEDFE6" }
          },
          font: { bold: true },
          border: {
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
            top: borderStyle
          }
        });
      });

      var results = employeeData.AntimonDetail

      results.forEach((task, index) => {
        row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

        columns.forEach((columnName, currentColumnIndex) => {
          Object.assign(row.getCell(columnIndex + currentColumnIndex), {
            value: task[columnName],
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "BEDFE6" }
            },
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle
            }
          });
        });
      });
      offset--;

      // // Boş satır
      // row = insertRow(masterRows[i].rowIndex + i + 1, offset++, 2);
      // Object.assign(row.getCell(columnIndex), {
      //   value: "",
      // });

      // // İkinci Detay Tablosunun Başlık Sütunu
      // row = insertRow(masterRows[i].rowIndex + i + 1, offset++, 2);
      // row.height = 20;
      // Object.assign(row.getCell(columnIndex), {
      //   value: "Detail2",
      //   fill: {
      //     type: "pattern",
      //     pattern: "solid",
      //     fgColor: { argb: "BEDFE6" }
      //   }
      // });

      // worksheet.mergeCells(row.number, columnIndex, row.number, columns.length + 1);
      // row = insertRow(masterRows[i].rowIndex + i + 1, offset++, 2);

      // columns.forEach((columnName, currentColumnIndex) => {
      //   Object.assign(row.getCell(columnIndex + currentColumnIndex), {
      //     value: columnName,
      //     fill: {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "BEDFE6" }
      //     },
      //     font: { bold: true },
      //     border: {
      //       bottom: borderStyle,
      //       left: borderStyle,
      //       right: borderStyle,
      //       top: borderStyle
      //     }
      //   });
      // });


    }

  }).then(function () {
    workbook.xlsx.writeBuffer()
      .then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
      });
  });
  e.cancel = true;
}


// For Devextreme HtmlEditor  
// Object type options that depend on other options' values (e.g. the widget options in the Toolbar) should be specified with an object.
// We recommend that you declare this object outside the configuration component to prevent issues caused by unnecessary re-rendering.
// https://supportcenter.devexpress.com/ticket/details/t850359/htmleditor-loses-focus-during-editing-if-toolbar-items-options-are-set
export const fontSizeOptions = {
  inputAttr: {
    'aria-label': 'Font size',
  },
};
export const fontFamilyOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
export const headerOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
export const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
export const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
export const headerValues = [false, 1, 2, 3, 4, 5];

export const setTimelineItem = (message, type) => {

  switch (message) {
    case "Campaign Created":
      if (type === "icon") {
        return <FontAwesomeIcon className='mt-1' icon={faRocket} style={{ fontSize: "23px" }} />
      }
      else if (type === "color") {
        return '#28a745'
      }
      break;
    case "Email Sent":
      if (type === "icon") {
        return <i class="ri-mail-fill" style={{ fontSize: "22px" }}></i>
      }
      else if (type === "color") {
        return '#28a745'
      }
      break;
    case "Email Opened":
      if (type === "icon") {
        return <i class="ri-mail-open-fill" style={{ fontSize: "22px" }} ></i>
      }
      else if (type === "color") {
        return '#f0ad4e'
      }
      break;
    case "Clicked Link":
      if (type === "icon") {
        return <i class="ri-cursor-fill" style={{ fontSize: "22px" }} ></i>
      }
      else if (type === "color") {
        return '#f39c12'
      }
      break;
    case "Email Reported":
      if (type === "icon") {
        return <i class="ri-megaphone-fill" style={{ fontSize: "22px" }} ></i>
      }
      else if (type === "color") {
        return '#5bc0de'
      }
      break;
    case "Submitted Data":
      if (type === "icon") {
        return <FontAwesomeIcon className='mt-1' icon={faExclamation} style={{ fontSize: "23px" }} />
      }
      else if (type === "color") {
        return '#d9534f'
      }
      break;
    case "Error Sending Email":
      if (type === "icon") {
        return <i class="ri-close-fill" style={{ fontSize: "22px" }} ></i>
      }
      else if (type === "color") {
        return '#d9534f'
      }

    default:
      if (type === "icon") {
        return <i class="ri-arrow-right-s-fill" style={{ fontSize: "23px" }} ></i>
      }
      else if (type === "color") {
        return 'grey'
      }
      break;

  }

}

export const getPackageInformation = async () => {

  return await axios.post(`${goFiberUrl}/api/user/get_user_info`, { Token: Cookies.get('Token') })
    .then((resp) => {

      let packageInformation = {
        IA: resp.data.IA,
        PS: resp.data.PS,
        SD: resp.data.SD,
        SDL: resp.data.SDL,
        SDLO: resp.data.SDLO,
        IAP: resp.data.IAP
      }

      Cookies.set('packageInformation', JSON.stringify(packageInformation))

      return packageInformation
      // return {
      //   PS: true,
      //   SD: true,
      //   SDL: false,
      //   SDLO: true,
      //   IA: true,
      // }
    })
    .catch((err) => {
      console.error(err);
    })
}

export function hasPermission(message, showNotify = false) {
  if (message == "Permission Denied") {
    if(showNotify)
      createNotification("error", <IntlMessages id='cs.perm-denied' />, <IntlMessages id='cs.perm-denied-mess' />, "filled")
    return false
  }
  return true

}

export async function getUserPerm(module_name) {
  try {
    const response = await axios.post(`${goFiberUrl}/api/users/get-user-perm`, {
      ApiKey: getApiKey(),
      token: Cookies.get('Token')
    });
    const permissions = response.data;

    // Eğer "Read All" veya "Write All" izinleri varsa doğrudan true döndür
    if (permissions['1'] === 'Read All' || permissions['2'] === 'Write All') {
      return true;
    }

    // Verilen module_name için izin kontrolü yap
    return Object.values(permissions).includes(module_name);
  } catch (err) {
    console.error(err);
    return false; // Hata durumunda false döndür
  }
}